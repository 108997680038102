import './StepByStep.css'

function StepByStep(props){
    return (
        <div className='step_container'>
            <h1 className='step_number'>{props.number}</h1>
            <h1 className='step_heading'><span className={props.span}>{props.heading}</span></h1>
            <p className='step_p'>{props.content}</p>
        </div>
    )
}
export default StepByStep;