import './Bottom.css'

function Bottom(){
    return (
        <div className='bottom'>
            <p className='bottom_text'>© Copyright 2024  | All rights reserved by TT SMM Panel | As Featured On <a href='https://bestsmmpanels.net/' className='link_white'>BestSMMPanels</a></p>
        </div>
    )
}

export default Bottom;