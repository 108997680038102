import './ServiceCard.css'

function ServiceCard(props){
    return (
        <div className='service_card'>
            <img src={props.src}/>
            <h2 className='service_heading'>{props.heading}</h2>
            <p className='service_p'>{props.content}</p>
        </div>
    )
}

export default ServiceCard;