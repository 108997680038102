import './Footer.css'
import chat from '../../images/chat_icon.svg'
function Footer(){
    return (
        <div className='footer'>
            <p className='footer_p'>Reply to <span className='gray'><img src={chat}/>YOU</span> So, let’s get started! You need to register, add funds, choose a service, and then sit back and relax. We’re here to take your social media presence to the next level! <b>#tt_smm_panel</b></p>
        </div>
    )
}

export default Footer;