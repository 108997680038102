import './Services.css'
import services from '../../images/services.png'
import ServiceCard from "../ServiceCard/ServiceCard";
import icon1 from '../../images/customer_support_icon.svg'
import icon2 from '../../images/order_tracking_icon.svg'
import icon3 from '../../images/easy_payment_icon.svg'
function Services(){
    return(
        <div id='services' className='services'>
            <img src={services}></img>
            <div className='services_list'>
                <ServiceCard src={icon1} heading='24/7 Customer Support' content='TikTok SMM Panel’s customer support is available round the clock. We believe in customer satisfaction, so you have the right to cancel and refill orders whenever you want.'/>
                <ServiceCard src={icon2} heading='Easy Order Tracking' content='With TikTok SMM Panel’s user-friendly dashboard, you no longer need to constantly update order information. We’ve made it very easy for you to place bulk orders, use multiple services at once, and track everything.'/>
                <ServiceCard src={icon3} heading='Easy Payment Process' content='TikTok SMM Panel is connected to all popular payment gateways to simplify the process for you. Once you place an order, TikTok SMM Panel will show you how many hours and minutes are left.'/>
            </div>
        </div>
    )
}

export default Services;