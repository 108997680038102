import './AboutUsCard.css'

function AboutUsCard(props){
    return(
        <div className='white_box'>
            <div className='red_box'>
                <p className='grid_number'>{props.number}</p>
            </div>
            <div className='content'>
                <h2 className='h2_header'>{props.header}</h2>
                <p className='p_content'>{props.content}</p>
            </div>
        </div>
    )
}

export default AboutUsCard;