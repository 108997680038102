import './Working.css'
import StepByStep from "../StepByStep/StepByStep";

function Working(){
    return(
        <div id='working' className='working'>
            <h1 className='working_heading'><span className='red'>HOW IT </span><span className='blue'>WORKS?</span></h1>
            <p className='working_p'>TikTok SMM Panel is an innovative social media marketing tool, a pool of several social media marketing services for growing your brand exponentially on various social media sites. You can use the metrics created by TikTok SMM Panel to boast about social proof and engagement to your target audience.</p>
            <div className='step_by_step'>
                <StepByStep number='1' span='red' heading='Create an Account' content='Register on TikTok SMM Panel by providing your email address, username, and password. We do not ask you to share your social media account passwords.'/>
                <StepByStep number='2' span='blue' heading='Add Funds' content='Next, you need to add funds to TikTok SMM Panel’s wallet. We have simplified various payment methods for you!'/>
                <StepByStep number='3' span='red' heading='Place an Order' content='As a wholesaler, we offer a wide selection of the best and cheapest SMM panel services. You can choose the best package that suits your goal and budget!'/>
                <StepByStep number='4' span='blue' heading='Boost Your Online' content='Now relax and watch your growth on social media. TikTok SMM Panel’s social media services will ensure your investments pay off doubly.'/>
            </div>
        </div>
    )
}

export default Working;