import Header from "./components/Header/Header";
import Hero from "./components/Hero/Hero";
import About from "./components/About/About";
import Services from "./components/Services/Services";
import Working from "./components/Working/Working";
import Footer from "./components/Footer/Footer";
import Bottom from "./components/Bottom/Bottom";
import Glowings from "./components/Glowings/Glowings";

function App() {
  return (
    <>
        <Glowings/>
        <Header/>
        <Hero/>
        <About/>
        <Services/>
        <Working/>
        <Footer/>
        <Bottom/>
    </>
  );
}

export default App;
