import './Hero.css'
import element from '../../images/3d_element.png'
function Hero(){
    return (
        <div id='hero' className='hero'>
            <img src={element}/>
            <h1 className='hero_h1_white'>your one-stop <span className='red'>solution</span> for <a href=' https://www.tiktok.com/' className='blue_link'><span
                className='blue'>TikTok</span></a></h1>
            <h1 className='hero_h1_black'><span className='white'>social media marketing.</span></h1>

            <p className='hero_p'>Our TikTok SMM PANEL offers a comprehensive range of<br/>services designed to boost your
                TikTok presence.</p>

        </div>
    )
}

export default Hero;