import './Glowings.css'
import glow1 from '../../images/glow_1.png'
import glow2 from '../../images/glow_2.png'
import glow3 from '../../images/glow_3.png'
import glow4 from '../../images/glow_4.png'
function Glowings(){
    return (
        <div className='glowings'>
            <img src={glow1} className='image1'/>
            <img src={glow2} className='image2'/>
            <img src={glow3} className='image3'/>
            <img src={glow4} className='image4'/>
        </div>
    )
}

export default Glowings;