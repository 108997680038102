import React, { useState, useEffect } from 'react';
import logo from '../../images/logo.png';
import sign_up from '../../images/sign_up_button.png';
import sign_in from '../../images/sign_in_button.png';
import './Header.css';

function Header() {
    const [signUpModalOpen, setSignUpModalOpen] = useState(false);
    const [signInModalOpen, setSignInModalOpen] = useState(false);

    const openSignUpModal = () => {
        setSignUpModalOpen(true);
    };

    const closeSignUpModal = () => {
        setSignUpModalOpen(false);
    };

    const openSignInModal = () => {
        closeSignUpModal();
        setSignInModalOpen(true);
    };

    const closeSignInModal = () => {
        setSignInModalOpen(false);
    };

    return (
        <div className='fixed_header'>
            <div className='header'>
                <img src={logo}/>
                <nav>
                    <a href='#hero'>HOME</a>
                    <a href='#about'>ABOUT US</a>
                    <a href='#services'>SERVICES</a>
                    <a href='#working'>HOW IT WORKS?</a>
                    <button className='sign_up' onClick={openSignUpModal}>
                        <img src={sign_up}/>
                    </button>
                </nav>
            </div>
            {signUpModalOpen && (
                <div className='modal'>
                    <div className='modal_content'>
                        <span className='close' onClick={closeSignUpModal}>×</span>
                        <form>
                            <h1 className='modal_heading'><span className='red'>SIGN</span> <span
                                className='blue'>UP</span></h1>
                            <input type="email" name="email" placeholder='Email'/>
                            <input type="password" name="password" placeholder='Password'/>
                            <button className='sign_up' onClick={openSignUpModal}>
                                <img src={sign_up}/>
                            </button>
                            <div className='or'>
                                <span className='red_circle'></span>
                                <p className='or_paragraph'>OR</p>
                                <span className='blue_circle'></span>
                            </div>
                            <a className='sign_in' onClick={openSignInModal}>SIGN IN</a>
                        </form>
                    </div>
                </div>
            )}
            {signInModalOpen && (
                <div className='modal'>
                    <div className='modal_content-2'>
                        <span className='close' onClick={closeSignInModal}>×</span>
                        <form>
                            <h1 className='modal_heading'><span className='red'>SIGN</span> <span
                                className='blue'>IN</span></h1>
                            <input type="email" name="email" placeholder='Email'/>
                            <input type="password" name="password" placeholder='Password'/>
                            <button className='sign_up' onClick={openSignInModal}>
                                <img src={sign_in}/>
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Header;
