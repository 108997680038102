import './About.css'
import chat from '../../images/chat_icon.svg'
import AboutUsCard from "../AboutUsCard/AboutUsCard";
function About(){
    return (
        <div id='about' className='about'>
            <h1 className='about_header'>ABOUT US <img src={chat}/></h1>
            <div className='grid'>
                <AboutUsCard number='1' header='Fast and Affordable SMM Services' content='TikTok SMM Panel prides itself on being the fastest and most affordable SMM panel provider. With services starting at just $0.001/1K, TikTok SMM Panel offers the best value for your money.'/>
                <AboutUsCard number='2' header='Reliable and Stable Services' content='With over 7,994,312 stable services and 803,342+ satisfied customers, our TikTok SMM Panel is a trusted provider of SMM services, especially TikTok services'/>
                <AboutUsCard
                    number='3'
                    header='Comprehensive Social Media Platforms'
                    content={<span>We offer SMM panels for various social media platforms including Facebook, Twitter, Pinterest, Instagram, YouTube, and of course, TikTok. For <a href="https://www.tiktok.com/" className='link'>TikTok</a> we are direct providers, while for other services we rely on <a href="https://topsmm.club/" className='link'>TopSMM</a> panel as provider.</span>}
                />
                <AboutUsCard number='4' header='Quality Services for Our Clients' content='TikTok SMM Panel’s mission is to provide you with the best SMM panel services to grow your social media accounts. We guarantee unprecedented organic growth of followers, subscribers, and likes.'/>
            </div>
        </div>
    )
}

export default About;